import React from "react";
import Slider from "react-slick";
const PrevArrow = ({ onClick }) => (
  <i
    className="fa-solid fa-angle-right button button-prev"
    onClick={onClick}
  ></i>
);

const NextArrow = ({ onClick }) => (
  <i
    className="fa-solid fa-angle-left  button button-next"
    onClick={onClick}
  ></i>
);
const Banner = () => {
  var settings = {
    dots: false,
    infinite: true,
    fade: false,

    useCSS: false,
    useTransform: false,

    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <PrevArrow />,
    prevArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };
  return (
    <React.Fragment>
      <div className="eksupport-banner mb-5" id="home">
        <Slider {...settings}>
          <div>
            <div className="bg1">
              <div className="banner-caption text-center">
                <h1 className="my-3" data-aos="fade-left">
                  Become Someone’s
                  <br />
                  EK SUPPORT
                </h1>
                <p className="fs-5" data-aos="fade-right">
                  Help us to eradicate poverty around the world and save the
                  million of lives from unwanted demises. Millions of innocent
                  lives we lost every year for malnutritions.
                </p>
                <a href="#contact" className="btn btn-donate mx-1" data-aos="fade-up">
                  Contact Now
                </a>
              </div>
            </div>
          </div>
          <div>
            <div className="bg2">
              <div className="banner-caption text-center">
                <h1 className="my-3 overflow-hidden" data-aos="fade-up">
                  If you can't feed a hundred people,
                  <br />
                  then feed just one
                </h1>
                <p data-aos="fade-up" className="overflow-hidden">
                  Help us to eradicate poverty around the world and save the
                  million of lives from unwanted demises. Millions of innocent
                  lives we lost every year for malnutritions.
                </p>
                <a
                  href="#contact"
                  className="btn btn-donate mx-1 overflow-hidden"
                  data-aos="fade-up"
                >
                  Contact Now
                </a>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </React.Fragment>
  );
};
export default Banner;
