/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from "react";
import Fancybox from "../../components/fancyBox/fancyBox";
import Tabs from "../../components/tabs/Tabs";
import categoriesData from "../../pages/gallery/categoriesData";

const GalleryNew = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  // useEffect(() => {
  //   Fancybox.bind('[data-fancybox="gallery"]', {
  //     Slideshow: {
  //       playOnStart: true,
  //     },
  //   });
  // }, []);

  // JavaScript code to handle smooth scrolling
  document.addEventListener("DOMContentLoaded", function () {
    const galleryTab = document.querySelector('a[href="#gallery2"]');

    // Check if the gallery tab exists
    if (galleryTab) {
      galleryTab.addEventListener("click", function (event) {
        event.preventDefault(); // Prevent default anchor behavior

        // Get the target element (gallery section)
        const gallerySection = document.querySelector(".tabs_outer");

        // Scroll to the gallery section smoothly
        gallerySection.scrollIntoView({ behavior: "smooth" });
      });
    }
  });

  return (
    <div className="container tabs_outer py-5" id="gallery2">
      <div className=" ">
        <h2 className="text-h2 mb-4 overflow-hidden" data-aos="fade-left">
          Gallery
        </h2>

        <Tabs
          data={categoriesData}
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
        />
        <Fancybox // Use the Fancybox component
          options={{
            Carousel: {
              infinite: false,
            },
          }}
        >
          {/* <div className="gallery_view flex justify-center items-start flex-wrap gap-4 pt-7 pb-7"> */}
          <div
            className={`gallery_view d-flex justify-content-center flex-wrap gap-4 pt-7 pb-7 align-items-center   ${
              window.innerWidth >= 768 ? "padding" : ""
            }`}
          >
            {categoriesData[activeTabIndex].content.map((img, index) => (
              <a href={img} data-fancybox="gallery" key={index}        data-aos="fade-left">
                <div className="">
                  <img
                    src={img}
                    alt="dwdw"
                    className="main"
                    // height={100}
                    // style={{
                    //   maxWidth:"100%"
                    //   objectFit: "cover",
                    //   objectPosition: "center",
                    //   borderRadius: "5px",
                    // }}
                  />
                </div>
              </a>
            ))}
          </div>
        </Fancybox>
      </div>
    </div>
  );
};
export default GalleryNew;
