import React from "react";
import support1 from "../../assets/images/13.jpg";
import support2 from "../../assets/images/gallary02.webp";
import support3 from "../../assets/images/gallary03.webp";
import support4 from "../../assets/images/gallary04.webp";

const Support = () => {
  return (
    <React.Fragment>
      <div className="  abc">
        <section className="bg-green p-5">
        <div className="container">
          <div className="row justify-content-center py-3">
          
            <div className="col-sm-8">
              <h3 className="text-white">Become a Support Partner</h3>
              <p className="text-white fs-6 justify">
                Join us in our mission to make a positive impact. Whether
                through volunteering, financial contributions, or spreading
                awareness, your support is integral to our success. Together,
                let's create a world where everyone has the support they need to
                thrive. Join us on our mission to make the world a better place,
                one act of support at a time.
              </p>
            </div>
            <div className="col-sm-4">
              {/* <a href="#" className="get ms-2">
                Volunteer
              </a> */}
              <a href="#contact" className="get">
                Get In Touch
              </a>
            </div>
          </div>
          <div className="row align-items-center">
            <div
              className="col-lg-3 col-6 my-1 overflow-hidden"
              data-aos="fade-left"
            >
              <img src={support1} alt={support1} className="w-100 img-fluid" />
            </div>
            <div
              className="col-lg-3 col-6 my-1 overflow-hidden"
              data-aos="fade-left"
            >
              <img src={support2} alt={support2} className="w-100 img-fluid" />
            </div>
            <div
              className="col-lg-3 col-6 my-1 overflow-hidden"
              data-aos="fade-right"
            >
              <img src={support3} alt={support3} className="w-100 img-fluid" />
            </div>
            <div
              className="col-lg-3 col-6 my-1 overflow-hidden"
              data-aos="fade-right"
            >
              <img src={support4} alt={support4} className="w-100 img-fluid" />
            </div>
          </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};
export default Support;
