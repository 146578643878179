import React, { useEffect } from "react";

import Layouts from "./layouts";
import Home from "./pages/home";
import Aos from "aos";
 

const App = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <React.Fragment>
      <Layouts>
        <Home />
      </Layouts>
    </React.Fragment>
  );
};

export default App;
