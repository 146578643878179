import image1 from "../../assets/images/gallary01.webp";
import image2 from "../../assets/images/gallary04.webp";
import image3 from "../../assets/images/gallary05.webp";
import gallery2 from "../../assets/images/image1.jpg";
import gallery4 from "../../assets/images/image2.jpg";
import gallery5 from "../../assets/images/image3.jpg";
import image4 from "../../assets/images/12.jpeg";
import image5 from "../../assets/images/image4.jpg";
import image6 from "../../assets/images/16 (1).jpg";
import image7 from "../../assets/images/13.jpg";
import image8 from "../../assets/images/covid.jpeg";
import image9 from "../../assets/images/15.jpg";

const categoriesData = [
  {
    label: "ALL",
    content: [
      image1,
      gallery4,
      gallery5,
      image5,
      image7,
      image4,
      image6,
      image8,
      image2,
      image3,
      gallery2,
    ],
  },
  // {
  //   label: "Events",
  //   content: [image5, image4, image6],
  // },
  {
    label: "DONATIONS",
    content: [image8, image2, image3, gallery2],
  },
  {
    label: "CHARITY",
    content: [image1, gallery4, gallery5],
  },
];

export default categoriesData;
