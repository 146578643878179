import { useEffect, useRef, useState } from "react";

const Tabs = ({ data, activeTabIndex, setActiveTabIndex }) => {
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const tabsRef = useRef([]);

  useEffect(() => {
    const setTabPosition = () => {
      const currentTab = tabsRef.current[activeTabIndex];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    };

    setTabPosition();
    window.addEventListener("resize", setTabPosition);

    return () => window.removeEventListener("resize", setTabPosition);
  }, [activeTabIndex]);

  return (
    <div className="relative ">
      <div className="d-flex flex-wrap md:g-5 align-items-center justify-content-center " data-aos="fade-right">
        {data.map((tab, idx) => (
          <button
            key={tab.label}
            type="button"
            ref={(el) => (tabsRef.current[idx] = el)}
            className={`pt-1 pb-2 px-1 md:px-1 xl:px-3 fontSize font-weight-bold md:fontBase 2xl:fontxl tabsbg mx-2 mb-4 ${
              activeTabIndex === idx
                ? "text-primary border tabsdesign border-orange-400 rounded-lg sm:border-none sm:rounded-none"
                : "text-gray-600"
            } focus-visible:outline-0`}
            onClick={() => setActiveTabIndex(idx)}
          >
            {tab.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
