import React from "react";

import mission from "../../assets/gif/mission.gif";
import approch from "../../assets/gif/goal.gif";
const AboutUs = () => {
  return (
    <React.Fragment>
      <div className="eksupport-about mb-5" id="about">
        <div className="container">
          <h2 className="text-h2 mb-5 overflow-hidden" data-aos="fade-down">
            About Us
          </h2>
          <div className="about-card">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-12 mb-4 mb-lg-0">
                <p
                  className="fs-5 justify overflow-hidden"
                  data-aos="fade-right"
                >
                  Ek Support Foundation is a non-profit organization established
                  in 2020 with a noble aim to provide assistance and support to
                  those in need. The name <b>"Ek Support"</b> reflects our
                  belief in the power of even a single act of support to
                  positively impact someone's life. It is inspired by the
                  profound impact that even a single act of support can have on
                  the lives of individuals facing challenges.
                </p>

                <h5 className="font-bold " data-aos="fade-left">
                  <b style={{ color: "#0141cf", fontSize: "28px" }}>
                    Our Mission{" "}
                    <img src={mission} alt="mission" className="img2" />
                  </b>{" "}
                </h5>
                <p
                  className="fs-5 justify overflow-hidden"
                  data-aos="fade-left"
                >
                  At Ek Support Foundation, our mission is to extend a helping
                  hand to individuals and communities facing various challenges,
                  including but not limited to health issues, education
                  barriers, and social inequalities. We are committed to making
                  a meaningful difference in the lives of those we serve by
                  offering support, resources, and opportunities for
                  empowerment.
                </p>
                <h5 className="font-bold" data-aos="fade-right">
                  <b style={{ color: "#0141cf", fontSize: "28px" }}>
                    Our Approach{" "}
                    <img src={approch} alt="mission" className="img2" />
                  </b>
                </h5>
                <p
                  className="fs-5 justify overflow-hidden"
                  data-aos="fade-right"
                >
                  Through collaboration with local communities, partners, and
                  stakeholders, we strive to create impactful programs and
                  initiatives that address root causes and promote positive
                  change.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AboutUs;
// import React from "react";
// const AboutUs = () => {
//   return (
//     <React.Fragment>
//       <div className="eksupport-about mb-5" id="about">
//         <div className="container">
//           <h2 className="text-h2 mb-5">About Us</h2>
//           <div className="about-card">
//             <div className="row">
//               <div className="col-sm-6 col-md-6 col-lg-12 mb-4 mb-lg-0">
//                 <p className="fs-6">
//                   Ek Support Foundation is a non-profit organization established
//                   in 2020 with a noble aim to provide assistance and support to
//                   those in need. The name <br/> <b>"Ek Support"</b> reflects our
//                   belief in the power of even a single act of support to
//                   positively impact someone's life. It is inspired by the
//                   profound impact that even a single act of support can have on
//                   the lives of individuals facing challenges.
//                 </p>
//                 <br />
//                 <h5 className="font-bold">Our Mission :</h5>
//                 <p>
//                   At Ek Support Foundation, our mission is to extend a helping
//                   hand to individuals and communities facing various challenges,
//                   including but not limited to health issues, education
//                   barriers, and social inequalities. We are committed to making
//                   a meaningful difference in the lives of those we serve by
//                   offering support, resources, and opportunities for
//                   empowerment.
//                 </p>
//                 <h5 className="font-bold">Our Approach :</h5>
//                 <p>
//                   Through collaboration with local communities, partners, and
//                   stakeholders, we strive to create impactful programs and
//                   initiatives that address root causes and promote positive
//                   change.
//                 </p>
//                 <h5 className="font-bold">Areas of Focus :</h5>
//                 <p>
//                   Ek Support Foundation works across diverse areas, including :
//                 </p>
//                 <ul>
//                   <li className="fw-bold">
//                     Education :{" "}
//                     <span className="fw-normal" style={{ fontSize: "14px" }}>
//                       {""}Ensuring access to quality education and educational
//                       resources for underprivileged children and youth.
//                     </span>
//                   </li>
//                   <li className="fw-bold">
//                     Healthcare :{" "}
//                     <span className="fw-normal" style={{ fontSize: "14px" }}>
//                       {""}Promoting healthcare awareness, providing medical
//                       assistance, and supporting initiatives to improve
//                       healthcare access.
//                     </span>
//                   </li>
//                   <li className="fw-bold">
//                     Livelihood :{" "}
//                     <span className="fw-normal" style={{ fontSize: "14px" }}>
//                       {""} Empowering individuals and communities through skill
//                       development, vocational training, and livelihood
//                       enhancement programs.
//                     </span>
//                   </li>
//                   <li className="fw-bold">
//                     Social Welfare :{" "}
//                     <span className="fw-normal" style={{ fontSize: "14px" }}>
//                       {""}Advocating for the rights and well-being of
//                       marginalized groups, including women, children, and
//                       persons with disabilities.
//                     </span>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </React.Fragment>
//   );
// };

// export default AboutUs;
