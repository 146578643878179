import React from "react";
import Slider from "react-slick";
import cause1 from "../../assets/images/gallary01.webp";
import cause2 from "../../assets/images/gallary02.webp";
import cause3 from "../../assets/images/gallary04.webp";

const PrevArrow = ({ onClick }) => (
  <i
    className="fa-solid fa-right-long button button-right"
    onClick={onClick}
  ></i>
);

const NextArrow = ({ onClick }) => (
  <i className="fa-solid fa-left-long button button-left" onClick={onClick}></i>
);
const OurCauses = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <PrevArrow />,
    prevArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };
  return (
    <React.Fragment>
      <div className="eksupport-causes mb-5">
        <div className="container">
          <h2 className="text-h2 mb-5 overflow-hidden" data-aos="zoom-in-down">
            Our Causes
          </h2>
          <div className="our-causes" id="abc">
            <Slider {...settings} className="">
              <div
                className=" card h-100 border-0 main-card overflow-hidden"
                data-aos="fade-right"
              >
                <div className="shadow">
                  <div className="main">
                    <img src={cause1} className="img-fluid" alt={cause1} />
                  </div>
                  <div className="py-4">
                    <h5 className="my-2 fw-bold ps-3">
                      Global Reach Local Presence
                    </h5>
                    <p className="ps-3">
                      There are many variations of passages of Lorem Ipsum
                      available, but the majo rity have suffered alteration
                    </p>
                    {/* <a href="#" className="ms-3">Donate Now</a> */}
                  </div>
                </div>
              </div>
              <div
                className="card h-100 border-0 main-card overflow-hidden"
                data-aos="fade-up"
              >
                <div className="shadow">
                  <div className="main">
                    <img src={cause2} className="img-fluid" alt={cause2} />
                  </div>
                  <div className="py-4">
                    <h5 className="my-2 fw-bold ps-3">
                      Donation For Helpless ChildAA
                    </h5>
                    <p className="ps-3">
                      Kushinagar and Kasia Bazar is a town and a historical
                      place located in the north-eastern marginal area of
                      ​​Uttar Pradesh
                    </p>
                    {/* <a href="#" className="ms-3">Donate Now</a> */}
                  </div>
                </div>
              </div>
              <div
                className="card h-100 border-0 main-card overflow-hidden"
                data-aos="fade-left"
              >
                <div className="shadow">
                  <div className="main">
                    <img src={cause3} className="img-fluid" alt={cause3} />
                  </div>
                  <div className="py-4">
                    <h5 className="my-2 fw-bold ps-3">Lorem Ipsum available</h5>
                    <p className="ps-3">
                      There are many variations of passages of Lorem Ipsum
                      available, but the majo rity have suffered alteration
                    </p>
                    {/* <a href="#" className="ms-3">Donate Now</a> */}
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default OurCauses;
