import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
const Footer = () => {
  return (
    <React.Fragment>
      <footer className=" eksupport-footer ">
        <section className="mx-5 mx-lg-0">
          <div className="container   pt-5 text-light">
            <div className="row">
              <div className="col-12  col-sm-12 col-md-3 col-lg-4 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  Ek Support Foundation
                </h6>
                <p className="justify ">
                  Ek Support Foundation is a non-profit organization established
                  in 2020 with a noble aim to provide assistance and support to
                  those in need.
                </p>
              </div>
              <div className="col-6 col-sm-6 col-md-3 col-lg-4 mb-4">
                <h6 className="text-uppercase fw-bold mb-4 ms-sm-5 ">
                  Quick Links
                </h6>
                <div className="d-sm-flex gap-5 ms-sm-5">
                  <p>
                    <a href="#about" className="text-light">
                      About Us
                    </a>
                  </p>
                  <p>
                    <a href="#what_we_do" className="text-light">
                      What We Do{" "}
                    </a>
                  </p>
                </div>
                <div className="d-sm-flex gap-5 ms-sm-5">
                  <p>
                    <a href="#gallery2" className="text-light">
                      Gallery
                    </a>
                  </p>
                  <p>
                    <a href="#contact" className="text-light ms-0 ms-lg-3">
                      Contact Us
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-3 col-lg-4 mb-4">
                <h6 className="text-uppercase fw-bold mb-4 ms-sm-5">
                  Get In Touch
                </h6>
                <div className="d-flex gap-5 ms-sm-5">
                  <a
                    href="https://www.facebook.com/people/Eksupportfoundation/100069542455737/?paipv=0&eav=Afadx2b3U3V4R4r3tr9e5BOfkVz9_nexwEHUkQhmvbfGHEM_Ono3AjzzfXwEF6rw7zQ"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faFacebook}
                      style={{ color: "white" }}
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/authwall?trk=bf&trkInfo=bf&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Feksupport-foundation%2Fabout%2F"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faLinkedinIn}
                      style={{ color: "white" }}
                    />
                    {/* <span href="#" className="text-light">
                    (+91) 172-4360000
              
                  </span> */}
                  </a>
                  <a
                    href="https://www.instagram.com/eksupportfoundation/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      style={{ color: "white" }}
                    />
                    {/* <i className="fa-solid fa-clock me-2"></i>
                  <span href="#" className="text-light">
                    9.30am-6.30pm
                  </span> */}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="text-center p-4 border-top text-light">
          ©2024 | Ek Support Foundation.
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
