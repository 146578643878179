import React, { useEffect, useState } from "react";
import icon2023 from "../../assets/images/icon2023.svg";
import icon2024 from "../../assets/images/icon2024.svg";
import icon2025 from "../../assets/images//icon2025.svg";
import icon2026 from "../../assets/images/icon20244.svg";
import child from "../../../src/assets/images/child.gif";
import news1 from "../../../src/assets/images/news1.png";
import news2 from "../../../src/assets/images/news2.jpg";
import news3 from "../../../src/assets/images/news3.jpg";
import news4 from "../../../src/assets/images/news4.png";

const AchieveTarget = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <React.Fragment>
      <div className="achieve-target text-center mb-5">
        <div className="container">
          <h2 className="text-h2 mb-4 overflow-hidden" data-aos="zoom-in-down">
            This is the impact YOU{" "}
            <span data-aos="zoom-in-down"> helped us achieve in 2022-23 </span>
          </h2>
          <div className="container mx-auto">
            {/* 
            <img src={child} alt="child" style={{ marginLeft: "-35px" }} /> */}
            {/* 
            <img
              src={child}
              alt="child"
              style={{
                objectFit: "cover",
                objectPosition: "center",
                borderRadius: "5px",
                width: windowWidth >= 768 ? "700px" : "230px", // Adjust width based on screen size
                height: windowWidth <= 768 ? "100px" : "75px", // Adjust height based on screen size
                marginLeft: windowWidth >= 768 ? "-35px" : "0", // Adjust margin for desktop or mobile
              }}
            /> */}
            <div className="container">
              <div className="row g-5">
                <div className="col-6 d-flex">
                  <img
                    src={news1}
                    className="w-100"
                    alt="News 1"
                    data-aos="fade-right"
                  />
                </div>
                <div className="col-6">
                  <img
                    src={news4}
                    className="w-100"
                    alt="News 2"
                    data-aos="fade-left"
                  />
                </div>
              </div>
              <div className="row g-5 mt-1">
                <div className="col-6">
                  <img
                    src={news3}
                    className="w-100"
                    alt="News 3"
                    data-aos="fade-up"
                  />
                </div>
                <div className="col-6 d-flex">
                  <img
                    src={news2}
                    className="w-100"
                    alt="News 4"
                    data-aos="fade-up"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="achieve-target-section">
                        <div className="target-section">
                            <div>
                                <img src={icon2023} alt="img" />
                            </div>
                            <div>
                                <div className="target-line target-first"></div>
                            </div>
                            <div>
                                <div className="text-start">
                                    <h5>85% children</h5>
                                    <p className="d-none d-sm-block">children in CRY project areas, under the age of 5 years, prevented from malnutrition </p>
                                </div>
                            </div>
                        </div>
                        <div className="target-section">
                            <div>
                                <img src={icon2026} alt="icon2026" />
                            </div>
                            <div>
                                <div className="target-line"></div>
                            </div>
                            <div>
                                <div className="text-start">
                                    <h5>65% children</h5>
                                    <p className="d-none d-sm-block">children in CRY project areas, under the age of 5 years, prevented from malnutrition </p>
                                </div>
                            </div>
                        </div>
                        <div className="target-section">
                            <div>
                                <img src={icon2024} alt="icon2024" />
                            </div>
                            <div>
                                <div className="target-line"></div>
                            </div>
                            <div>
                                <div className="text-start">
                                    <h5>55% children</h5>
                                    <p className="d-none d-sm-block">children in CRY project areas, under the age of 5 years, prevented from malnutrition </p>
                                </div>
                            </div>
                        </div>
                        <div className="target-section">
                            <div>
                                <img src={icon2025} alt="icon2025" />
                            </div>
                            <div>
                                <div className="target-line target-end"></div>
                            </div>
                            <div>
                                <div className="text-start">
                                    <h5>75% children</h5>
                                    <p className="d-none d-sm-block">children in CRY project areas, under the age of 5 years, prevented from malnutrition </p>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};
export default AchieveTarget;
