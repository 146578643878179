import gallery2 from "../../assets/images/gallary02.webp";
import gallery4 from "../../assets/images/gallary04.webp";
import gallery5 from "../../assets/images/gallary05.webp";
import image1 from "../../assets/images/image1.jpg";
import image2 from "../../assets/images/image2.jpg";
import image3 from "../../assets/images/image3.jpg";
import image4 from "../../assets/images/12.jpeg";
import image5 from "../../assets/images/image4.jpg";
import image6 from "../../assets/images/16 (1).jpg";
import image7 from "../../assets/images/13.jpg";
import image8 from "../../assets/images/covid.jpeg";
import image9 from "../../assets/images/15.jpg";

export const tabData = {
  All: [
    gallery2,
    gallery4,
    gallery5,
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
  ],
  Charity: [gallery4, gallery5, gallery2, image8],
  Nature: [gallery2, gallery4, image1, image2],
  Wildlife: [gallery4, gallery2],
  Children: [gallery4, image4, image5],
};
