import React from "react";
import image1 from "../../assets/images/edu.gif";
import image2 from "../../assets/images/giphy-1.gif";
import image3 from "../../assets/images/sefty.gif";
import image4 from "../../assets/images/childp-1.gif";

const About = () => {
  return (
    <React.Fragment>
      <div className="eksupport-about mb-5" id="what_we_do">
        <div className="container">
          <h2
            className="text-h2 mb-5 overflow-hidden overflowHidden"
            data-aos="fade-up"
          >
            What We Do
          </h2>
          {/* <div>
             <p className="fs-5 ">
              Ek Support Foundation works across diverse areas, including :
            </p>
            <ul>
              <li className="fw-bold fs-5">
                Education :{" "}
                <span className="fw-normal ">
                  {""}Ensuring access to quality education and educational
                  resources for underprivileged children and youth.
                </span>
              </li>
              <li className="fw-bold fs-5">
                Healthcare :{" "}
                <span className="fw-normal ">
                  {""}Promoting healthcare awareness, providing medical
                  assistance, and supporting initiatives to improve healthcare
                  access.
                </span>
              </li>
              <li className="fw-bold fs-5">
                Livelihood :{" "}
                <span className="fw-normal">
                  {""} Empowering individuals and communities through skill
                  development, vocational training, and livelihood enhancement
                  programs.
                </span>
              </li>
              <li className="fw-bold fs-5">
                Social Welfare :{" "}
                <span className="fw-normal">
                  {""}Advocating for the rights and well-being of marginalized
                  groups, including women, children, and persons with
                  disabilities.
                </span>
              </li>
           
            </ul>
          </div> */}
          <div className="about-card">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-3 mb-3 mb-lg-0">
                <div
                  class="flip-card overflow-hidden overflowHidden"
                  tabIndex="0"
                  data-aos="zoom-in-up"
                  data-aos-delay="500"
                >
                  <div class="flip-card-inner">
                    <div class="flip-card-front">
                      <div className="text-center py-3">
                        <img src={image1} alt={image1} />
                      </div>
                      <div>
                        <h5 className="pb-5">Education</h5>
                        {/* <div className="service-btn d-none d-lg-block">
                          <a href="#">Donate Now</a>
                        </div> */}
                      </div>
                    </div>
                    <div class="flip-card-back">
                      <p>
                        Ensuring access to quality education and educational
                        resources for underprivileged children and youth.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3 mb-3 mb-lg-0">
                <div
                  class="flip-card overflow-hidden overflowHidden"
                  tabIndex="0"
                  data-aos="zoom-in-down"
                  data-aos-delay="800"
                >
                  <div class="flip-card-inner">
                    <div class="flip-card-front">
                      <div className="text-center py-3">
                        <img
                          src={image2}
                          alt={image1}
                          style={{ marginRight: "-31px" }}
                        />
                      </div>
                      <div className="">
                        <h5 className="pb-5">Healthcare</h5>
                        {/* <div className="service-btn d-none d-lg-block">
                          <a href="#">Donate Now</a>
                        </div> */}
                      </div>
                    </div>
                    <div class="flip-card-back">
                      <p>
                        Promoting healthcare awareness, providing medical
                        assistance, and supporting initiatives to improve
                        healthcare access.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3 mb-3 mb-lg-0">
                <div
                  class="flip-card overflow-hidden overflowHidden"
                  tabIndex="0"
                  data-aos="zoom-in-down"
                  data-aos-delay="1000"
                >
                  <div class="flip-card-inner">
                    <div class="flip-card-front">
                      <div className="text-center py-3">
                        <img src={image3} alt={image1} />
                      </div>
                      <div className="">
                        <h5 className="pb-5">Livelihood</h5>
                        {/* <div className="service-btn d-none d-lg-block">
                          <a href="#">Donate Now</a>
                        </div> */}
                      </div>
                    </div>
                    <div class="flip-card-back">
                      <p>
                        Empowering individuals and communities through skill
                        development, vocational training, and livelihood
                        enhancement programs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3 mb-3 mb-lg-0">
                <div
                  class="flip-card overflow-hidden overflowHidden"
                  tabIndex="0"
                  data-aos="zoom-in-down"
                  data-aos-delay="1200"
                >
                  <div class="flip-card-inner">
                    <div class="flip-card-front">
                      <div className="text-center py-3">
                        <img src={image4} alt={image1} />
                      </div>
                      <div className="">
                        <h5 className="pb-5">Social Welfare </h5>
                        {/* <div className="service-btn d-none d-lg-block">
                          <a href="#">Donate Now</a>
                        </div> */}
                      </div>
                    </div>
                    <div class="flip-card-back">
                      <p>
                        Advocating for the rights and well-being of marginalized
                        groups, including women, children, and persons with
                        disabilities.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-3 mb-4 mb-lg-0">
                <div className="card  border-0 h-100 text-center">
                  <div className="text-center py-3">
                    <img src={image1} alt={image1} />
                  </div>
                  <div className="card-body">
                    <h5 className="pb-5">Education</h5>
                    <div className="service-btn d-none d-lg-block">
                      <a href="#">Donate Now</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3 mb-4 mb-lg-0">
                <div className="card  border-0 h-100 text-center">
                  <div className="text-center py-3">
                    <img src={image2} alt={image2} />
                  </div>
                  <div className="card-body">
                    <h5 className="pb-5">Memorial</h5>
                    <div className="service-btn d-none d-lg-block">
                      <a href="#">Donate Now</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3 mb-4 mb-lg-0">
                <div className="card  border-0 h-100 text-center">
                  <div className="text-center py-3">
                    <img src={image3} alt={image3} />
                  </div>
                  <div className="card-body">
                    <h5 className="pb-5">Charity</h5>
                    <div className="service-btn d-none d-lg-block">
                      <a href="#">Donate Now</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3 mb-4 mb-lg-0">
                <div className="card  border-0 h-100 text-center">
                  <div className="text-center py-3">
                    <img src={image4} alt={image4} />
                  </div>
                  <div className="card-body">
                    <h5 className="pb-5">Medical</h5>
                    <div className="service-btn d-none d-lg-block">
                      <a href="#">Donate Now</a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="row">
                            <div className="col-sm-6 col-md-6 col-lg-3 mb-3 mb-lg-0">
                                <div className="card  h-100">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <img src={image1} alt={image1} />
                                        <div className="main-text">01</div>
                                    </div>
                                    <div className="card-body">
                                        <h5>Education</h5>
                                        <p>We are ipsum dolor sit amet, ctetu ad ipisicing elit, sed do eiusmod por idnt ut labore et dolore agna aliqua. enim ad minim miam, quis nostrud scitation expetendis in mei.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-3 mb-3 mb-lg-0">
                                <div className="card  h-100">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <img src={image2} alt={image2} />
                                        <div className="main-text">02</div>
                                    </div>
                                    <div className="card-body">
                                        <h5>Memorial</h5>
                                        <p>We are ipsum dolor sit amet, ctetu ad ipisicing elit, sed do eiusmod por idnt ut labore et dolore agna aliqua. enim ad minim miam, quis nostrud scitation expetendis in mei.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-3 mb-3 mb-lg-0">
                                <div className="card  h-100">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <img src={image3} alt={image3} />
                                        <div className="main-text">03</div>
                                    </div>
                                    <div className="card-body">
                                        <h5>Charity</h5>
                                        <p>We are ipsum dolor sit amet, ctetu ad ipisicing elit, sed do eiusmod por idnt ut labore et dolore agna aliqua. enim ad minim miam, quis nostrud scitation expetendis in mei.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-3 mb-3 mb-lg-0">
                                <div className="card  h-100">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <img src={image4} alt={image4} />
                                        <div className="main-text">04</div>
                                    </div>
                                    <div className="card-body">
                                        <h5>Medical</h5>
                                        <p>We are ipsum dolor sit amet, ctetu ad ipisicing elit, sed do eiusmod por idnt ut labore et dolore agna aliqua. enim ad minim miam, quis nostrud scitation expetendis in mei.</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default About;
