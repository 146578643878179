import React, { useEffect, useState } from "react";
import { tabData } from "./data";
import { SlideshowLightbox } from "lightbox.js-react";

const Gallery = () => {
  const [currentTab, setCurrentTab] = useState("All");
  const tabs = Object.keys(tabData);
  const handleTabClick = (tabName) => {
    setCurrentTab(tabName);
  };

  // gallery
  let [modalOpen, setModalOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  useEffect(() => {
    if (selectedImages.length > 0) {
      setModalOpen(true);
    }
  }, [selectedImages]);

  return (
    <React.Fragment>
      <div className="gallery-section mb-5" id="gallery">
        <div className="container">
          <h2 className="text-h2 mb-4 overflow-hidden" data-aos="fade-left">
            Gallery
          </h2>
          <div className="gallery">
            <div className="gallery-tab">
              {tabs?.map((tab, index) => (
                <button
                  key={index}
                  onClick={() => handleTabClick(tab)}
                  className={`${tab === currentTab ? "active" : ""}`}
                >
                  {tab}
                </button>
              ))}
            </div>
            <div
              className="gallery-image overflow-hidden"
              data-aos="fade-down-right"
            >
              <div className="row">
                {tabData[currentTab]?.map((imageUrl, index) => (
                  <div className="col-sm-6 col-md-3 px-1" key={index}>
                    <div
                      className="main"
                      onClick={() => {
                        const createData = tabData[currentTab]?.map((item) => {
                          return {
                            src: item,
                            alt: item,
                          };
                        });
                        setSelectedImageIndex(index);
                        setSelectedImages(createData);
                      }}
                    >
                      {index < 12 && (
                        <div>
                          <img
                            key={imageUrl}
                            src={imageUrl}
                            alt={imageUrl}
                            className="img-fluid"
                          />
                          <i className="fa-solid fa-plus" id="myImg"></i>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                <div className="position-relative">
                  <SlideshowLightbox
                    iconColor="silver"
                    // disableImageZoom={true}

                    images={selectedImages}
                    startingSlideIndex={selectedImageIndex}
                    showThumbnails={false}
                    open={modalOpen}
                    lightboxIdentifier="lbox1"
                    onClose={() => {
                      setModalOpen(false);
                    }}
                  ></SlideshowLightbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Gallery;
