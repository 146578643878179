import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const form = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleChangeName = (e) => {
    const name = e.target.value;
    // Regular expression to check if the input contains only letters
    const onlyLettersRegex = /^[A-Za-z\s]+$/;
    // Check if the input matches the regular expression
    if (onlyLettersRegex.test(name) || name === "") {
      // If input is valid (contains only letters) or is empty, update state
      setFormData({ ...formData, name });
    }
    // If input contains numbers or other invalid characters, do nothing (don't update state)
  };

  const handleChangemsg = (e) => {
    const message = e.target.value;
    // Split the input value by whitespace to count words
    const wordCount = message.trim().split(/\s+/).length;
    if (wordCount <= 100) {
      // If word count is within limit, update state
      setFormData({ ...formData, message });
    } else {
      // If word count exceeds limit, truncate the input
      const truncatedMessage = message
        .trim()
        .split(/\s+/)
        .slice(0, 100)
        .join(" ");
      setFormData({ ...formData, message: truncatedMessage });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate form here
    if (validateForm()) {
      console.log("Form submitted successfully:", formData);
      // Email sending functionality
      emailjs
        .sendForm("service_aatnbop", "template_rifem1p", form.current, {
          publicKey: "mVFR5gsUxToz0q8yD",
        })
        .then(
          () => {
            console.log("SUCCESS!");
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );
      toast.success("Message sent successfully!", {
        style: {
          backgroundColor: "#ffff",
          color: "#1d6f47",
        },
      });
      setFormData({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
      // You can perform additional actions like sending data to server, etc.
    } else {
      console.error("Form submission failed. Please check your inputs.");
    }
  };

  const validateForm = () => {
    return (
      formData.name && formData.email && formData.subject && formData.message
    );
    // You can add more complex validation rules here
  };

  return (
    <React.Fragment>
      <ToastContainer autoClose={5000} />
      <div className="contact mb-5" id="contact">
        <div className="container">
          <div className="row">
            <h2 className="text-h2 mb-5 overflow-hidden" data-aos="fade-left">
              Contact Us
            </h2>
            <p
              className="fs-5  mb-3 justify overflow-hidden px-lg-5 text-center"
              data-aos="fade-right"
            >
              Join us in our mission to make a positive impact. Whether through
              volunteering, financial contributions, or spreading awareness,
              your support is integral to our success.
              <br />
            </p>
            <b
              className="text-center fs-4 mb-5 wrapper  overflow-hidden"
              data-aos="fade-left"
            >
              Join us on our mission to make the world a better place, one act
              of support at a time.
            </b>
            {/* <p className="fs-5  mb-5 justify">
              To learn more about Ek Support Foundation, our programs, and how
              you can contribute, please visit our website or contact us
              directly. Your support can make a significant difference in the
              lives of those we serve. Join us in our mission to make a positive
              impact. Whether through volunteering, financial contributions, or
              spreading awareness, your support is integral to our success.
              Together, let's create a world where everyone has the support they
              need to thrive. Join us on our mission to make the world a better
              place, one act of support at a time.
            </p> */}
            <div className="col-sm-6 col-md-6 col-lg-6 mb-3 mb-md-0">
              <div
                className="contact-form overflow-hidden"
                data-aos="fade-down-right"
              >
                <form onSubmit={handleSubmit} ref={form}>
                  <div className="contact-info mb-3">
                    <h4>DROP US A MESSAGE</h4>
                  </div>
                  <div className="row">
                    <div className="col">
                      <input
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleChangeName}
                        required
                      />
                    </div>
                    <div className="col">
                      <input
                        type="email"
                        name="email"
                        placeholder="Your Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <input
                        type="text"
                        name="subject"
                        placeholder="Subject"
                        value={formData.subject}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <textarea
                        name="message"
                        placeholder="Your Message"
                        rows="4"
                        value={formData.message}
                        onChange={handleChangemsg}
                        required
                      ></textarea>
                    </div>
                  </div>
                  <button type="submit" className="default-btn">
                    <span>Submit</span>
                  </button>
                </form>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 mb-3 mb-md-0">
              <div
                className="contact-info overflow-hidden"
                data-aos="fade-down-left"
              >
                <div className="mb-2">
                  <h4>CONTACT INFO</h4>
                  <p className="justify fs-5 " style={{ fontWeight: 420 }}>
                    To learn more about Ek Support Foundation, our programs, and
                    how you can contribute, you can contact us directly. Your
                    support can make a significant difference in the lives of
                    those we serve.
                  </p>
                </div>
                <div>
                  <ul>
                    {/* <li className="fw-bold">
                      <i className="fa-solid fa-location-dot"></i>
                      XEAM Tower, E-202, Phase- 8-B, Chandigarh (Punjab)
                    </li> */}
                    <li className="fw-bold">
                      <i className="fa fa-envelope"></i>{" "}
                      <a href="mailto:info@eksupportfoundation.com">
                        info@eksupportfoundation.com
                      </a>
                    </li>
                    {/* <li className="fw-bold">
                      <i className="fa-solid fa-phone-volume"></i>(+91)
                      172-4360000
                    </li> */}

                    {/* <li className="fw-bold">
                      <i className="fa-solid fa-globe"></i>
                      eksupportfoundation.com
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Contact;
