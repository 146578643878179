import React from "react";
import Banner from "../banner";
import About from "../about";
import OurCauses from "../our";
import AchieveTarget from "../achieveTarget";
import Gallery from "../gallery";
import Contact from "../contact";
import Support from "../support";
import AboutUs from "../about_us";
import GalleryNew from "../gallery/indexnew";
const Home = () => {
  return (
    <React.Fragment>
      <Banner />
      <AboutUs />
      <About />
      <OurCauses />
      <AchieveTarget />
      {/* <Gallery /> */}
      <GalleryNew />
      <Contact />
      <Support />
    </React.Fragment>
  );
};
export default Home;
