import React from "react";
import eksupport from "../../assets/images/eksupport.png";
const Header = () => {
  return (
    <React.Fragment>
      <nav
        className="eksupport-header navbar navbar-expand-lg navbar-light bg-white sticky-top"
        id="header"
        style={{ boxShadow: "0px 4px 8px #888888" }}
      >
        <div className="container-fluid">
          <a className="navbar-brand mx-3" href="/">
            <img src={eksupport} className="logo img-fluid" alt={eksupport} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item mx-3">
                <a
                  className="nav-link text-uppercase"
                  aria-current="page"
                  href="#about"
                >
                  About Us
                </a>
              </li>
              <li className="nav-item mx-3">
                <a
                  className="nav-link text-uppercase"
                  aria-current="page"
                  href="#what_we_do"
                >
                  What We Do
                </a>
              </li>
              <li className="nav-item mx-3">
                <a
                  className="nav-link text-uppercase"
                  aria-current="page"
                  href="#gallery2"
                >
                  Gallery
                </a>
              </li>
              <li className="nav-item mx-3">
                <a
                  className="nav-link text-uppercase"
                  aria-current="page"
                  href="#contact"
                >
                  Contact Us
                </a>
              </li>
            </ul>
            {/* <a className="btn btn-info donate" href="#contact">
              Donate
              <i className="fa-solid fa-heart ms-2"></i>
            </a> */}
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default Header;
